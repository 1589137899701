<template>
    <div>
      <PageLoader :storage="appLoading" />
      <ServerError v-if="ServerError" />
      <v-snackbar v-model="showSnackBar" color="#FF6907" right :timeout="timeout">
        <v-layout wrap justify-center>
          <v-flex text-left class="align-self-center">
            <span style="color: #fff">{{ msg }}</span>
          </v-flex>
          <v-flex text-right>
            <v-btn small :ripple="false" text @click="showSnackBar = false">
              <v-icon style="color: #fff">mdi-close</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-snackbar>
      <v-layout wrap justify-center>
        <v-flex xs12>
          <v-layout wrap justify-center>
            <v-flex xs12>
              <v-layout wrap justify-start pa-5>
                <v-flex xs12>
                  <span class="heading">Snake Details</span>
                </v-flex>
                <v-flex xs12>
                  <v-layout wrap justify-start>
                    <v-flex xs12>
                      <v-card flat>
                        <v-layout wrap justify-start pa-2>
                          <v-flex xs12 sm12 lg7 text-center align-self-center  v-if="photographs.length > 0">
                            <v-layout wrap justify-start>
                              <v-flex xs12>
                                <v-carousel>
                                  <v-carousel-item
                                    v-for="(item, i) in photographs"
                                    :key="i"
                                    :src="mediaURL + item.url"
                                    reverse-transition="fade-transition"
                                    transition="fade-transition"
                                  ></v-carousel-item>
                                </v-carousel>
                              </v-flex>
                            </v-layout>
                          </v-flex>
                          <v-flex xs12 sm12 lg5>
                            <v-layout wrap justify-start pl-lg-2>
                              <v-flex xs12>
                                <v-layout wrap justify-start>
                                  <v-flex xs12>
                                    <span class="heading1"
                                      >{{ fulldata.name }} ({{
                                        fulldata.scientificName
                                      }})</span
                                    >
                                  </v-flex>
                                  <v-flex xs12 pt-3>
                                    <span class="heading1">
                                      <span
                                        v-if="
                                          fulldata.venomType === 'Highly Venomous'
                                        "
                                        style="color: red"
                                        >{{ fulldata.venomType }}</span
                                      >
                                      <span
                                        v-if="
                                          fulldata.venomType === 'Mildly Venomous'
                                        "
                                        style="color: orange"
                                        >{{ fulldata.venomType }}</span
                                      >
                                      <span
                                        v-if="
                                          fulldata.venomType === 'Non Venomous'
                                        "
                                        style="color: green"
                                        >{{ fulldata.venomType }}</span
                                      >
                                    </span>
                                  </v-flex>
                                  <v-flex xs12 pt-3 v-if="fulldata.rname">
                                    <span class="heading1">Regional Names:</span
                                    ><span class="subheading"
                                      >&nbsp;{{ fulldata.rname }}</span
                                    >
                                  </v-flex>
                                  <v-flex xs12 pt-3 v-if="fulldata.family">
                                    <span class="heading1">Family:</span
                                    ><span class="subheading"
                                      >&nbsp;{{ fulldata.family }}</span
                                    >
                                  </v-flex>
                                  <v-flex xs12 pt-3 v-if="fulldata.distribution">
                                    <span class="heading1">Distribution:</span
                                    ><span v-html="fulldata.distribution"></span>
                                  </v-flex>
                                  <v-flex xs12 v-if="fulldata.wlpa">
                                    <span class="heading1">WLPA:</span
                                    ><span class="subheading"
                                      >&nbsp;{{ fulldata.wlpa }}</span
                                    >
                                  </v-flex>
                                  <v-flex xs12 pt-3 v-if="fulldata.iucn">
                                    <span class="heading1">IUCN:</span
                                    ><span class="subheading"
                                      >&nbsp;{{ fulldata.iucn }}</span
                                    >
                                  </v-flex>
                                </v-layout>
                              </v-flex>
                            </v-layout>
                          </v-flex>
                        </v-layout>
                        <v-layout wrap justify-start pa-2>
                          <v-flex xs12>
                            <v-layout wrap justify-start v-if="fulldata && fulldata.characteristics">
                              <v-flex xs12
                                ><span class="heading1">Scalation:</span></v-flex
                              >
                              <v-flex xs12
                                ><span v-html="fulldata.characteristics"></span
                              ></v-flex>
                            </v-layout>
                            <v-layout wrap justify-start v-if="fulldata && fulldata.description">
                              <v-flex xs12
                                ><span class="heading1">Morphology:</span></v-flex
                              >
                              <v-flex xs12
                                ><span v-html="fulldata.description"></span
                              ></v-flex>
                            </v-layout>
                            <v-layout wrap justify-start v-if="states && states.length > 0">
                              <v-flex xs12>
                                <span class="heading1">States: </span>
                                <span class="subheading" v-for="(item, i) in states" :key="i"
                                  ><span v-if="item"
                                    >{{ item }}
                                    <span v-if="i < states.length - 1"
                                      >,
                                    </span></span
                                  >
                                  <span v-else>UnKnown</span>
                                </span>
                              </v-flex>
                            </v-layout>
                          </v-flex>
                        </v-layout>
                      </v-card>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </div>
  </template>
        <script>
  import axios from "axios";
  export default {
    data() {
      return {
        appLoading: false,
        ServerError: false,
        showSnackBar: false,
        timeout: 5000,
        msg: null,
        fulldata: {},
        photographs: [],
        states: [],
      };
    },
    beforeMount() {
      this.getData();
    },
    methods: {
      formatDate(date) {
        if (!date) return "-";
        const [year, month, day] = date.slice(0, 10).split("-");
        return `${day}-${month}-${year}`;
      },
      getData() {
        this.appLoading = true;
        axios({
          method: "POST",
          url: "/snake/" + this.$route.query.id,
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
        })
          .then((response) => {
            if (response.data.status == true) {
              {
                this.fulldata = response.data.data;
                this.photographs = this.fulldata.photographs;
                this.states = this.fulldata.states;
                this.msg = response.data.msg;
                this.showSnackBar = false;
                this.appLoading = false;
              }
            } else {
              this.msg = response.data.msg;
              this.showSnackBar = true;
              this.appLoading = false;
            }
          })
          .catch((err) => {
            this.appLoading = false;
            (this.ServerError = true), console.log(err);
          });
      },
    },
  };
  </script>
        <style>
  .heading {
    font-family: poppinsregular;
    font-size: 20px;
    font-weight: 900;
  }
  .heading1 {
    font-family: poppinsregular;
    font-size: 16px;
    font-weight: 900;
  }
  .subheading {
    font-family: poppinsregular;
    font-size: 15px;
    font-weight: 500;
  }
  </style>